.experience-section-exp {
  margin-top: 20px; /* Add margin between sections */
  margin-bottom: 20px; /* Add margin between sections */
}

.section-title-exp {
  text-align: center; /* Center align section titles */
  margin-bottom: 30px; /* Add margin below section titles */
}

.experience-grid-exp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align the grid items */
  gap: 20px; /* Add gap between the cards */
}

.experience-box-exp {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 450px; /* Adjust card width */
  width: 100%;
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  align-items: center; /* Center align items */
  text-align: center; /* Center align text */
}

.text-content-exp {
  flex: 1; /* Take the remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Center align text */
  padding: 20px; /* Add padding inside the text content */
}

.text-content-exp h2 {
  font-size: 1.2em;
  color: #434343;
  margin: 0 0 5px 0;
}

.text-content-exp hr {
  border: 0;
  height: 1px;
  background-color: #e1e1e1;
  margin: 5px 0;
  width: 100%; /* Ensure horizontal rule takes full width */
}

.text-content-exp p {
  font-size: 0.9em;
  color: #161616;
  margin: 5px 0;
}

.image-contain-exp {
  display: flex; /* Added to center align */
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
  width: 120px; /* Adjust the size */
  height: 120px; /* Adjust the size */
  margin-top: 10px; /* Add margin above the image */
  top: -20px; /* Move the image up */
  left: 42px; /* Move the image left */
}

.img-inborder-exp {
  max-width: 100%; /* Ensure the image doesn't exceed container */
  max-height: 100%; /* Ensure the image doesn't exceed container */
  border-radius: 30%; /* Circular shape for the image */
}

@media (max-width: 768px) {
  .experience-box-exp {
    padding: 15px; /* Smaller padding on smaller screens */
  }

  .text-content-exp p {
    font-size: 0.85em; /* Slightly smaller text on mobile */
  }
}
