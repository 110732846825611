.footer-wrapper {
  
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  padding: 20px 0;
  color: #333;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.list-inline {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.list-inline li {
  margin: 0 10px;
}

.fa-inverse {
  color: #9f9f9f;
}

