.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #0072ff, #00c6ff);
  padding: 20px 40px;
  color: white;
  font-size: 18px;
}

.header-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-and-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the text to the left */
}

.header-name {
  font-size: 22px;
  font-weight: bold;
}

.header-title {
  font-size: 18px; /* Smaller font size for the title */
  font-weight: normal; /* Less emphasis on the title */
  color: #e0e0e0; /* Lighter color for the title */
}

.header-nav a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
}

.header-nav a:hover {
  text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .header-nav a {
    font-size: 16px;
    margin: 0 10px;
  }

  .header-name {
    font-size: 20px; /* Adjust the size on smaller screens */
  }

  .header {
    padding: 10px 20px;
  }
}

